
















import { Component, Prop, Vue } from 'vue-property-decorator'
import CardPanel from './CardPanel.vue'

@Component({ 
  components:{ 
    CardPanel
  }
})
export default class CardPanelWithTitle extends Vue {
  @Prop({default: ''})
  title !: string;
}
