import { render, staticRenderFns } from "./CardPanel.vue?vue&type=template&id=00f87abd&scoped=true&"
import script from "./CardPanel.vue?vue&type=script&lang=ts&"
export * from "./CardPanel.vue?vue&type=script&lang=ts&"
import style0 from "./CardPanel.vue?vue&type=style&index=0&id=00f87abd&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f87abd",
  null
  
)

export default component.exports