


















































import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import CardPanelWithTitle from '@/components/Card/CardPanelWithTitle.vue'
import PhraseLink from '@/components/PhraseLink/PhraseLink.vue'
import { ProfileService } from '@/services/ProfileService'
import { Address } from '@/models/Address';
import { DynamicTranslationService } from '@/services/DynamicTranslationService'


@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.homeTitle
  }),
  components: {
    CardWithTitle, 
    CardPanelWithTitle,
    PhraseLink
  }
})
export default class Home extends Vue {
  get contactUsUrl(){ 
    return ProfileService.profile?.contactUsUrl || '';
  }

  get accountName() { 
    return ProfileService.profile?.org.name || '';
  }

  get accountNumber() { 
    return ProfileService.profile?.org.account || '';
  }

  get countryName() { 
    return ProfileService.profile?.org.countryName || '';
  }

  get publicEmailAddress() { 
    return ProfileService.profile?.org.publicEmailAddress || '';
  }

  get publicPhoneNumber() { 
    return ProfileService.profile?.org.publicPhoneNumber || '';
  }

  get publicWebsite() { 
    return ProfileService.profile?.org.publicWebsite || '';
  }

  get physicalAddress(){ 
    if (!ProfileService.profile){
      return this.$t('R.LIT_MissingAddress').toString();
    }
    return this.concatenateAddress(ProfileService.profile.org.address.physical);
  }

  concatenateAddress(address: Address | null) { 
    if (!address ||  (!address.line1 && !address.city && !address.postalCode && !address.countryCode)){
      return this.$t('R.LIT_MissingAddress').toString();
    }

    return [
      address.line1,
      address.line2,
      `${address.city}, ${address.stateOrProvince} ${address.postalCode}`,
    ].filter( p => !!p).join('<br/>');
  }
  

}
