import { render, staticRenderFns } from "./CardPanelWithTitle.vue?vue&type=template&id=42806e82&scoped=true&"
import script from "./CardPanelWithTitle.vue?vue&type=script&lang=ts&"
export * from "./CardPanelWithTitle.vue?vue&type=script&lang=ts&"
import style0 from "./CardPanelWithTitle.vue?vue&type=style&index=0&id=42806e82&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42806e82",
  null
  
)

export default component.exports